import React from "react";

function App(props) {
    return (
        <>
<svg width="28" height="26" viewBox="0 0 28 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path className={"star-icon " + [props.extraClass]}
        d="M14 0L17.1432 9.67376H27.3148L19.0858 15.6525L22.229 25.3262L14 19.3475L5.77101 25.3262L8.9142 15.6525L0.685208 9.67376H10.8568L14 0Z"/>
</svg>


        </>
    );
}

export default App