import React, {useState} from "react";
import StarIcon from "../../images/icons/star.js"
import ga from "../../utils/ga";
import useTranslations from "../../utils/useTranslations";

export default (props) => {
    const t = useTranslations(props.text)
    let stars=[1,2,3,4,5];
    const LAST_SHOWN_TIME = "lastStarRatingTime"


    let showRating = true;

    let dateNumberString = null;

    if (typeof window !== 'undefined') {
        dateNumberString = localStorage.getItem(LAST_SHOWN_TIME);
    }

    if (dateNumberString !== null) {
        let lastNum = Number.parseInt(dateNumberString);
        let timeSinceLastShown = Date.now() - lastNum;
        let cutoff = 1000 * 60 * 60 * 24;

        if (timeSinceLastShown < cutoff) {
            showRating = false
        }
    }





    let [hoverRating, updateHoverRating] = useState(0)


    let [ratingState, updateRatingState] = useState(showRating ? 0 : 2)

    function starRating(rating){
        ga("Help Center","rating",rating)

        updateRatingState(1)

        setTimeout(()=>close(),3000);
    }

    function close(){
        if (typeof window !== 'undefined') {
            localStorage.setItem(LAST_SHOWN_TIME, Date.now().toString())
        }
        updateRatingState(2)
    }




    return <>
            <div className={"help-center-rating mt-5 ml-auto"+ (ratingState === 2 ? "hidden": "")}>
                <div className="">

                <div className="close-icon" onClick={()=>close()}>
                    x
                </div>

                {ratingState === 0 && <>

                    <div className="header">
                        {t("helpCenterReview")}
                    </div>

                   <div className="rating">
                        <div className={"star-holder"}>
                            {stars.map(star =>
                                <div className={"star"}
                                     onMouseOver={()=>updateHoverRating(star)}
                                     onMouseLeave={()=>updateHoverRating(0)}
                                     onClick={()=>{starRating(star)}}
                                     key={"Star"+star}>
                                    <StarIcon extraClass={ hoverRating >= star ? "filled-star" : ""}/>
                                </div>
                            )}
                        </div>

                    </div>
                </>}

                {ratingState === 1 && <div className="thank-you">
                    {t("helpCenterReviewThanks")}
                </div>}
                </div>

            </div>
        </>
}