import React from "react";
import { navigate } from 'gatsby';
import LocalizedLink from "../localizedLink"
import HelpCenterLinks from "./helpCenterLinks";
import useTranslations from "../../utils/useTranslations";
import { LocaleContext } from "../layout";
import locales from "../../../config/i18n";

export default (props) => {
    const t = useTranslations(props.text)
    const { locale } = React.useContext(LocaleContext);

    function handleSubmit(e) {
        if(e.key === "Enter") {
            const path = `/help/search/?value=${e.target.value}`;
            const localizedPath = locales[locale].default
                ? path
                : `/${locales[locale].path}${path}`
            navigate(localizedPath)
        }
    }

    return <>
            <div className="help-center-search">
                <div className="search-container">

                    <div className="text">
                        <LocalizedLink to={HelpCenterLinks.homeLink} className="search-text"><span className={"font-weight-bold"}>BitTorrent</span> {t("helpCenter")}</LocalizedLink>
                    </div>

                    <div className="search-box">
                        <input id="search" className="search-input" placeholder={t("searchPrompt")} onKeyDown={handleSubmit}/>
                    </div>
                </div>

            </div>
        </>
}