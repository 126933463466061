import React from "react";
import arrowWhite from "../../images/icons/arrow-white.svg";
import {navigate} from "gatsby";
import useTranslations from "../../utils/useTranslations";


export default (props) => {
    const t = useTranslations(props.text)



    function buttonClicked(){
        navigate("/blog")
    }


    return <>
            <div className="help-center-blog p-4 mb-5 ml-auto" onClick={()=>buttonClicked()}>
                <div className="text">{t("blogInfo")}</div>
                <div className="button" >{t("visitBlog")} <img src={arrowWhite} alt={t("visitBlog")}/></div>
            </div>
        </>
}