import React from "react";
import {Link} from "gatsby";


export default (params) => {
    return <>
        <div className="help-center-breadcrumb">

            <div className="crumb-holder">
                {params.breadCrumb.map(crumb =>
                    <span key={crumb.name}>
                        <Link to={crumb.link}>{crumb.name}</Link>
                        {crumb.isLast ? "" : " /"}
                    </span>)}
            </div>
        </div>

        </>
}