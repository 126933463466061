import React from "react"
import HelpCenterLinks from "../../components/help-center/helpCenterLinks";
import HelpCenterSearch from "../../components/help-center/helpCenterSearch";
import HelpCenterBlog from "../../components/help-center/helpCenterBlog";
import HelpCenterBreadCrumb from "../../components/help-center/helpCenterBreadCrumb";
import HelpCenterRating from "../../components/help-center/helpCenterRating";
import useTranslations from "../../utils/useTranslations";

export default ({data, pageContext: {solutionInfo, applications, text}}) => {
    const t = useTranslations(text)

    function getBreadCrumb(){
        let breadCrumb = [{
            name: t("helpCenter"),
            link: HelpCenterLinks.homeLink,
            isLast: false,
        }];

        breadCrumb.push({
            name: solutionInfo.name,
            link:"",
            isLast: true,

        })

        return breadCrumb;
    }


    return (
        <>
            <div className="help-center-product-page">
                <HelpCenterSearch text={text}/>
                <HelpCenterBreadCrumb breadCrumb={getBreadCrumb()}/>

                <div className="body">
                    <div className="m-0 p-0 ">


                        <h1>
                            {solutionInfo.name}
                        </h1>


                        <div className="help-thing">

                            {solutionInfo.folderIndexes.map(folder =>
                                    <div className="category-column">
                                <div className="header-text">
                                    {folder.title}
                                </div>
                                <div>
                                    {folder.articleList !== undefined && folder.articleList.map(article =>
                                        <div className="link-holder">
                                            <a href={HelpCenterLinks.articleLink("en",article.id,article.title)}
                                               className="link-text">{article.title}</a>
                                        </div>
                                    )}
                                </div>
                            </div>
                            )}
                        </div>

                    </div>

                    <div>
                        <HelpCenterBlog text={text}/>
                        <HelpCenterRating text={text}/>
                    </div>
                </div>


            </div>

    </>
    )
}